import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const Settings = () => {
    const [smtpSettings, setSmtpSettings] = useState({
        smtp_host: '',
        smtp_port: '',
        smtp_username: '',
        smtp_password: '',
        tls_enabled: true,
    });

    const [userSettings, setUserSettings] = useState({
        industry: '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;

    useEffect(() => {
        if (userId) {
            fetchSmtpSettings(userId);
            fetchUserSettings(userId);
        }
    }, [userId]);

    // Fetch the user's existing SMTP settings
    const fetchSmtpSettings = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/smtp/smtp-settings/${userId}`);
            if (response.data) {
                setSmtpSettings(response.data);
            }
        } catch (error) {
            console.error('Error fetching SMTP settings:', error);
            toast.error('Failed to load SMTP settings');
        }
    };

    // Fetch the user's existing user settings
    const fetchUserSettings = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/user/update-user-settings/${userId}`);
            if (response.data) {
                setUserSettings({
                    ...userSettings,
                    industry: response.data.industry,
                });
            }
        } catch (error) {
            console.error('Error fetching user settings:', error);
            toast.error('Failed to load user settings');
        }
    };

    // Handle SMTP input changes
    const handleSmtpChange = (e) => {
        setSmtpSettings({
            ...smtpSettings,
            [e.target.name]: e.target.value,
        });
    };

    // Handle User Settings input changes
    const handleUserSettingsChange = (e) => {
        setUserSettings({
            ...userSettings,
            [e.target.name]: e.target.value,
        });
    };

    // Handle TLS toggle
    const handleTlsToggle = () => {
        setSmtpSettings({
            ...smtpSettings,
            tls_enabled: !smtpSettings.tls_enabled,
        });
    };

    // Submit the SMTP settings
    const handleSmtpSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/server/crm_function/api/smtp/smtp-settings', {
                ...smtpSettings,
                userId,  // Include the userId in the payload
            });
            toast.success('SMTP settings saved successfully!');
        } catch (error) {
            console.error('Error saving SMTP settings:', error);
            toast.error('Failed to save SMTP settings');
        }
    };

    // Submit the User settings (industry and password)
    const handleUserSettingsSubmit = async (e) => {
        e.preventDefault();

        // Password validation
        if (userSettings.newPassword !== userSettings.confirmPassword) {
            toast.error("New password and confirm password don't match");
            return;
        }

        try {
            const response = await axios.post('/server/crm_function/api/user/update-user-settings', {
                userId,
                industry: userSettings.industry,
                currentPassword: userSettings.currentPassword,
                newPassword: userSettings.newPassword
            });
            toast.success('User settings updated successfully!');
        } catch (error) {
            console.error('Error saving user settings:', error);
            toast.error('Failed to save user settings');
        }
    };

    return (
        <div className="settings-page p-4">
            <h2>SMTP Settings</h2>
            <Form onSubmit={handleSmtpSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="smtpHost">
                            <Form.Label>SMTP Host</Form.Label>
                            <Form.Control
                                type="text"
                                name="smtp_host"
                                value={smtpSettings.smtp_host}
                                onChange={handleSmtpChange}
                                placeholder="e.g., smtp.gmail.com"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="smtpPort">
                            <Form.Label>SMTP Port</Form.Label>
                            <Form.Control
                                type="number"
                                name="smtp_port"
                                value={smtpSettings.smtp_port}
                                onChange={handleSmtpChange}
                                placeholder="e.g., 587"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="smtpUsername">
                            <Form.Label>SMTP Username</Form.Label>
                            <Form.Control
                                type="text"
                                name="smtp_username"
                                value={smtpSettings.smtp_username}
                                onChange={handleSmtpChange}
                                placeholder="Your email address"
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="smtpPassword">
                            <Form.Label>SMTP Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="smtp_password"
                                value={smtpSettings.smtp_password}
                                onChange={handleSmtpChange}
                                placeholder="Your SMTP password"
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="tlsEnabled">
                            <Form.Check
                                type="checkbox"
                                label="Enable TLS"
                                checked={smtpSettings.tls_enabled}
                                onChange={handleTlsToggle}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" type="submit">
                    Save SMTP Settings
                </Button>
            </Form>

            <hr />

            <h2>User Settings</h2>
            <Form onSubmit={handleUserSettingsSubmit}>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="userIndustry">
                            <Form.Label>Industry</Form.Label>
                            <Form.Control
                                as="select"
                                name="industry"
                                value={userSettings.industry}
                                onChange={handleUserSettingsChange}
                            >
                                <option value="">Select Industry</option>
                                <option value="Tech">Tech</option>
                                <option value="Finance">Finance</option>
                                <option value="Health">Health</option>
                                <option value="Education">Education</option>
                                {/* Add other industries as needed */}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="currentPassword">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="currentPassword"
                                value={userSettings.currentPassword}
                                onChange={handleUserSettingsChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="newPassword"
                                value={userSettings.newPassword}
                                onChange={handleUserSettingsChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="confirmPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="confirmPassword"
                                value={userSettings.confirmPassword}
                                onChange={handleUserSettingsChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" type="submit">
                    Save User Settings
                </Button>
            </Form>
        </div>
    );
};

export default Settings;
