import React, { useEffect, useState } from 'react';
import {Form, Button, ListGroup, Col, Card, ProgressBar} from 'react-bootstrap';
import DashboardCards from '../CRMcomponents/DashboardCards';
import DashboardCharts from '../CRMcomponents/DashboardCharts';
import axios from 'axios';
import '../CRMstyles/Dashboard.css';  // Ensure you have similar responsive CRMstyles here

const Dashboard = () => {
    const [userName, setUserName] = useState('');
    const [userIndustry, setUserIndustry] = useState('');
    const [dashboardData, setDashboardData] = useState({
        lists: 0,
        publicLists: 0,
        privateLists: 0,
        subscribers: 0,
        campaigns: 0,
        finishedCampaigns: 0,
        draftCampaigns: 0,
        messagesSent: 0,
        campaignViews: 0,
        linkClicks: 0,
        timePeriods: [] // Initialize empty timePeriods array
    });

    useEffect(() => {
        const user = localStorage.getItem('user');
        if (user) {
            const parsedUser = JSON.parse(user);
            setUserName(parsedUser.name || 'Guest');
            fetchDashboardData(parsedUser.id);
        }
    }, []);

    useEffect(() => {
        // Dynamically load the RSS ticker script after component mounts
        const script = document.createElement('script');
        script.src = "https://widget.rss.app/v1/ticker.js";
        script.async = true;
        document.body.appendChild(script);

        // Clean up script to avoid duplication on re-renders
        return () => {
            document.body.removeChild(script);
        };
    }, []);  // This useEffect only runs once after the component mounts

    const fetchDashboardData = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/dashboard/${userId}`);
            const data = response.data;
            console.log('industry is ' +  data.industry)
            setUserIndustry(data.industry); //

            // Deconstruct timePeriods from the response
            const timePeriods = data.timePeriods.map((period) => ({
                name: period.period, // Set 'name' for the chart based on 'period'
                newContacts: period.newContacts || 0,
                unsubscribes: period.unsubscribes || 0,
                bounces: period.bounces || 0,
                opens: period.opens || 0,
                clicks: period.clicks || 0
            }));

            setDashboardData({
                lists: data.totalLists,
                publicLists: data.publicLists,
                privateLists: data.privateLists,
                subscribers: data.totalSubscribers,
                campaigns: data.totalCampaigns,
                finishedCampaigns: data.sentCampaigns,
                draftCampaigns: data.draftCampaigns,
                messagesSent: data.messagesSent,
                campaignViews: data.campaignViews,
                linkClicks: data.linkClicks,
                timePeriods: timePeriods, // Pass the deconstructed timePeriods to the state
                recentEvents: data.recentEvents || []  // Add recentEvents here
            });
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    const getTickerId = (industry) => {
        switch (industry) {
            case 'Real Estate':
                return 'tOiggT7g2s6B43o4';
            case 'Finance':
                return 'tczIVO69TxVo83tO';
            case 'Tech':
                return 'tmiycRgWp9Q0dAjT';
            case 'Insurance':
                return 'taZGapwkthHkLrQG';
            case 'Health':
                return 'tMEB4ssF9JUrMuGe';
            default:
                return null; // Return null or a default RSS ticker if industry is unknown
        }
    };
    return (


        <Card className="" style={{ height: '100%' , backgroundColor:'white', marginBottom:'0px'}}>
        <div className="dashboard" >

            {/* Conditionally Render RSS Ticker */}
            {userIndustry && (
                <rssapp-ticker id={getTickerId(userIndustry)}></rssapp-ticker>
            )}

            <div className="sidebar-header p-3">


        {/*        <h3>Welcome, {userName}!</h3>*/}
            </div>
            {/* RSS Ticker */}

            <div className="main-content">
                <h1 style={{textAlign:'center'}}>Dashboard</h1>
                <div className="dashboard-cards">
                    <DashboardCards
                        lists={dashboardData.lists}
                        publicLists={dashboardData.publicLists}
                        privateLists={dashboardData.privateLists}
                        subscribers={dashboardData.subscribers}
                        campaigns={dashboardData.campaigns}
                        finishedCampaigns={dashboardData.finishedCampaigns}
                        draftCampaigns={dashboardData.draftCampaigns}
                        recentEvents={dashboardData.recentEvents}  // Pass recentEvents here
                    />

                </div>


   {/*             <DashboardCharts
                    totalSubscribers={dashboardData.subscribers}
                    timePeriods={dashboardData.timePeriods}  // Pass time periods to the DashboardCharts
                />*/}
            </div>

        </div>

        </Card>

    );
};

export default Dashboard;
