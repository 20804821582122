import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FaList, FaUserFriends, FaBullhorn, FaCalendarCheck } from 'react-icons/fa';

const DashboardCards = ({
                            lists,
                            subscribers,
                            campaigns,
                            recentEvents = []
                        }) => {
    const cardStyle = {
        color: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderStyle: 'solid',
        borderColor: 'white',
        borderWidth: '.5em'
    };
    const cardTextStyle = {
        fontSize: 'xx-large',
        color: 'white'
    };

    // Scrolling state for recent events
    const [currentEventIndex, setCurrentEventIndex] = useState(0);

    useEffect(() => {
        // Set up an interval to scroll through events every 3 seconds
        const intervalId = setInterval(() => {
            setCurrentEventIndex((prevIndex) => (prevIndex + 1) % recentEvents.length);
        }, 3000); // Change the interval time (3000 ms) if you want slower or faster scrolling

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [recentEvents.length]);

    // Get the current event to display
    const currentEvent = recentEvents[currentEventIndex];

    return (


        <Row>
            {/* Other cards for Lists, Subscribers, Campaigns */}





            {/* Recent Events Card with Auto-Scrolling */}


            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4" style={cardStyle}>
                    <img src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1733514628/subscribers.001_kwwld0.jpg"/>
                    <Card.Img as={FaUserFriends} size={48} className="m-3" style={{ color: '#de4e7f' }} />
                    <Card.Body style={{ background: 'linear-gradient(to bottom right, #f5c2d5, #de4e7f)' }}>
                        <Card.Title>Subscribers</Card.Title>
                        <Card.Text style={cardTextStyle}>{subscribers} Subscribers</Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4" style={cardStyle}>
                    <img src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1733517271/Lists.001_yozwhy.jpg"/>
                    <Card.Img as={FaList} size={48} className="m-3" style={{ color: '#28a745' }} />
                    <Card.Body style={{ background: 'linear-gradient(to bottom right, #7fc87f, #008B00)' }}>
                        <Card.Title>Lists</Card.Title>
                        <Card.Text style={cardTextStyle}>{lists} Lists</Card.Text>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4" style={cardStyle}>
                    <img src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1733520343/Campaigns.001_pslyjv.jpg"/>
                    <Card.Img as={FaBullhorn} size={48} className="m-3" style={{ color: '#ffa726' }} />
                    <Card.Body style={{ background: 'linear-gradient(to bottom right, #ffdab3, orange)' }}>
                        <Card.Title>Campaigns</Card.Title>
                        <Card.Text style={cardTextStyle}>{campaigns} Campaigns</Card.Text>
                    </Card.Body>
                </Card>
            </Col>


            <Col xs={12} md={6} lg={3}>
                <Card className="mb-4" style={cardStyle}>
                    <img src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1733521487/Recent_Events.001_nhf0sk.jpg"/>
                    <Card.Img as={FaCalendarCheck} size={48} className="m-3" style={{ color: 'cadetblue' }} />
                    <Card.Body style={{ background: 'linear-gradient(to bottom right, #a9d8d8, cadetblue)' }}>

                        <Card.Title>Recent Events</Card.Title>
                        {currentEvent ? (
                            <div>
                                <Card.Text style={cardTextStyle}>
                                    <strong>{currentEvent.name}</strong>
                                </Card.Text>
                                opened the email on{' '}
                                {new Date(currentEvent.opened_at).toLocaleString()}
                            </div>
                        ) : (
                            <p>No recent events</p>
                        )}
                    </Card.Body>
                </Card>
            </Col>

        </Row>
    );
};

export default DashboardCards;
