import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

const SubscriberDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [subscriber, setSubscriber] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchSubscriber();
    }, [id]);

    const fetchSubscriber = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/server/crm_function/api/subscribers/${id}`);
            const data = await response.json();
            setSubscriber(data);
        } catch (error) {
            console.error('Error fetching subscriber:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        const userId = JSON.parse(localStorage.getItem('user')).id; // Retrieve user_id
        try {
            await fetch(`/server/crm_function/api/subscribers/${id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...subscriber, user_id: userId }), // Include user_id in the update
            });
            navigate('/subscribers');  // Redirect back after saving
        } catch (error) {
            console.error('Error updating subscriber:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSubscriber({ ...subscriber, [name]: value });
    };

    return loading ? (
        <p>Loading...</p>
    ) : (
        <div className="subscriber-details p-4">
            <h2>Subscriber Details</h2>
            <Form>
                <Form.Group controlId="email">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                        type="email"
                        name="email"
                        value={subscriber.email || ''}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="name" className="mt-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={subscriber.name || ''}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group controlId="customer" className="mt-3">
                    <Form.Label>Customer</Form.Label>
                    <Form.Control
                        as="select"
                        name="customer"
                        value={subscriber.customer || 'enabled'}
                        onChange={handleChange}
                    >
                        <option value="enabled">Enabled</option>
                        <option value="disabled">Disabled</option>
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" className="mt-3" onClick={handleSave}>
                    Save
                </Button>
            </Form>
        </div>
    );
};

export default SubscriberDetails;
