import React, { useEffect, useState } from 'react';
import { ProgressBar, Card, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { FaPlus, FaRocket } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';  // Toastify for notifications
import 'react-toastify/dist/ReactToastify.css';
import '../CRMstyles/CampaignPage.css';
import CampaignCreate from "./CampaignCreate";
import workflowContainer from "./WorkflowContainer";
// Import Chart.js components
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import WorkflowContainer from "./WorkflowContainer";

// Register necessary chart.js components
ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const CampaignPage = () => {
    const navigate = useNavigate();

    // State for campaigns and progress
    const [campaigns, setCampaigns] = useState([]);
    const [campaignProgress, setCampaignProgress] = useState(100);  // Example campaign progress

    // State for "All Campaigns" stats
    const [allSentCount, setAllSentCount] = useState(0);
    const [allScheduledCount, setAllScheduledCount] = useState(0);
    const [allDraftCount, setAllDraftCount] = useState(0);

    // State for "Recent Campaign" stats
    const [recentSendCount, setRecentSendCount] = useState(0);
    const [recentScheduledCount, setRecentScheduledCount] = useState(0);
    const [recentDraftCount, setRecentDraftCount] = useState(0);

    // State for unsubscribe counts
    const [unsubscribeCounts, setUnsubscribeCounts] = useState({});

    const [userLists, setUserLists] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);  // Campaign selected for editing lists
    const [selectedCampaignLists, setSelectedCampaignLists] = useState([]);  // Store selected lists for the campaign
    const [showModal, setShowModal] = useState(false);  // Modal visibility

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        fetchCampaigns();  // Fetch campaigns on mount
    }, []);

    // Function to fetch campaigns for the logged-in user
    const fetchCampaigns = async () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            try {
                const response = await axios.get(`/server/crm_function/api/campaigns/user/${user.id}`);
                const campaigns = response.data;
                const sortedCampaigns = campaigns.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                setCampaigns(sortedCampaigns);
                if (sortedCampaigns.length > 0) {
                    setCampaignProgress(sortedCampaigns[0].progress || 0);
                    setSelectedCampaign(sortedCampaigns[0].id);  // Set the first campaign as default
                    fetchCampaignStats(sortedCampaigns[0].id);  // Fetch stats for the first campaign
                }

                // Fetch unsubscribe counts
                fetchUnsubscribeCounts(user.id);

                // Fetch user lists
                const listResponse = await axios.get(`/server/crm_function/api/lists/user/${user.id}`);
                setUserLists(listResponse.data);
            } catch (error) {
                console.error('Error fetching campaigns:', error);
            }
        }
    };

    // Function to fetch stats for all campaigns
    const fetchAllCampaignStats = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/campaigns/stats/${userId}`);
            const { sent_count, scheduled_count, draft_count } = response.data;
            setAllSentCount(sent_count || 0);
            setAllScheduledCount(scheduled_count || 0);
            setAllDraftCount(draft_count || 0);
        } catch (error) {
            console.error('Error fetching campaign stats:', error);
        }
    };

    // Function to fetch stats for a selected campaign
    const fetchCampaignStats = async (campaignId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/campaigns/${campaignId}/stats`);
            const { send_count, scheduled_count, draft_count } = response.data;  // Use send_count here
            setRecentSendCount(send_count || 0);  // Updated to send_count
            setRecentScheduledCount(scheduled_count || 0);
            setRecentDraftCount(draft_count || 0);
        } catch (error) {
            console.error('Error fetching campaign stats:', error);
        }
    };

    // Fetch unsubscribe counts for campaigns
    const fetchUnsubscribeCounts = async (userId) => {
        try {
            const response = await axios.get(`/server/crm_function/api/unsubscribes/user/${userId}`);
            setUnsubscribeCounts(response.data);  // Example format: { campaignId: count }
        } catch (error) {
            console.error('Error fetching unsubscribe counts:', error);
        }
    };

    // Handle campaign selection
    const handleCampaignSelection = (e) => {
        const selectedCampaignId = e.target.value;
        setSelectedCampaign(selectedCampaignId);
        fetchCampaignStats(selectedCampaignId);  // Fetch stats for selected campaign
    };

    // Pagination calculations
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCampaigns = campaigns.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(campaigns.length / itemsPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Get campaign lists by list IDs
    const getCampaignLists = (listIds) => {
        return listIds.map(id => {
            const list = userLists.find(list => list.id === id);
            return list ? list.name : `List ID: ${id}`;
        }).join(', ');
    };

    // Handle running the campaign again (resend)
    const handleRunCampaignAgain = async (campaignId) => {
        try {
            // Fetch the campaign details by ID
            const response = await axios.get(`/server/crm_function/api/campaigns/${campaignId}`);
            const campaignData = response.data;

            // Retrieve the userId from local storage or wherever it's stored in your app
            const user = JSON.parse(localStorage.getItem('user')); // Assuming user info is stored in localStorage
            const userId = user.id; // Assuming the user object contains an 'id' field

            // Add userId to the campaign data
            const payload = {
                ...campaignData,
                userId,  // Add the userId to the payload
            };

            // Send the campaign again with the userId
            await axios.post(`/server/crm_function/api/campaigns/send/${campaignId}`, payload);

            toast.success('Campaign sent successfully!');
        } catch (error) {
            console.error('Error resending campaign:', error);
            toast.error('Failed to resend the campaign.');
        }
    };

    // Open modal to edit lists for the selected campaign
    const handleEditLists = (campaign) => {
        setSelectedCampaign(campaign);
        setSelectedCampaignLists(campaign.list_ids);  // Preselect the lists for the campaign
        setShowModal(true);  // Open modal
    };

    // Handle saving updated lists for the campaign
    const handleSaveLists = async () => {
        try {
            const updatedCampaign = {
                ...selectedCampaign,
                list_ids: selectedCampaignLists  // Update with the newly selected lists
            };

            await axios.put(`/server/crm_function/api/campaigns/${selectedCampaign.id}`, updatedCampaign);  // Update campaign lists

            setShowModal(false);
            fetchCampaigns();  // Refresh campaigns
            toast.success('Campaign lists updated successfully!');
        } catch (error) {
            console.error('Error updating campaign lists:', error);
            toast.error('Failed to update campaign lists.');
        }
    };

    // Handle multi-select list change
    const handleListChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions);
        const selectedListIds = selectedOptions.map((option) => option.value);
        setSelectedCampaignLists(selectedListIds);  // Update selected lists
    };

    const handlePreview = (htmlContent) => {
        const previewWindow = window.open('', 'Preview', 'width=600,height=800');
        previewWindow.document.write(`
        <html>
            <head>
                <title>Campaign Preview</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                </style>
            </head>
            <body>
                ${htmlContent}
            </body>
        </html>
    `);
        previewWindow.document.close();
    };


    return (
        <div className="campaign-page">

            <Row className=" align-items-center">

            </Row>



            <ToastContainer position="top-right" autoClose={3000} />


            {/* All Campaigns and Recent Campaigns Section */}
            <Row styl={{marginBottom:'0px!important'}} className="">
                <WorkflowContainer/>
               {/* <Col md={6}>

                    <Card className="recent-campaign-card" style={{ height: '100%' , backgroundColor:'white'}}>
                        <img style={{margin:'auto'}} width="200px" src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1732061697/marketing_nuyvhq.gif"/>
                        <Button variant="primary" onClick={() => navigate('/campaigns/create')}>
                            <FaPlus /> Create Campaign
                        </Button>
                        <Card.Body style={{background:'linear-gradient(to bottom right, #f5c2d5, steelblue'}}>
                            <div className="d-flex align-items-center mb-4">
                                <h3 style={{ color: 'steelblue' }}>Create a New Campaign</h3>
                            </div>
                            <Card.Title>All Campaigns</Card.Title>
                            <p>Sent - {allSentCount}</p>
                            <p>Scheduled - {allScheduledCount}</p>
                            <p>Drafts - {allDraftCount}</p>
                            <ProgressBar now={campaignProgress} label={`${campaignProgress}% Opened`} />
                        </Card.Body>
                    </Card>
                </Col>*/}

                <Col md={6}>

           {/*         <Card className="recent-campaign-card" style={{ height: '100%', padding:'0px', marginBottom:'0px',backgroundColor:'white' }}>
                        <img style={{margin:'auto'}} width="200px" src="https://res.cloudinary.com/duz4vhtcn/image/upload/v1732061697/marketing_nuyvhq.gif"/>

                        <CampaignCreate campaigns={campaigns} />


                    </Card>*/}
                </Col>
            </Row>
            {/*            <Card.Body style={{background:'linear-gradient(to bottom right, #f5c2d5, #de4e7f)',padding:'0px'}}>
                            <Card.Title>Recent Campaigns</Card.Title>

                            <select className="form-control mb-3" onChange={handleCampaignSelection} value={selectedCampaign}>
                                {campaigns.length > 0 ? (
                                    campaigns.map(campaign => (
                                        <option key={campaign.id} value={campaign.id}>
                                            {campaign.name}
                                        </option>
                                    ))
                                ) : (
                                    <option>No campaigns available</option>
                                )}
                            </select>
                        </Card.Body>*/}
       {/*     <Row className="mb-4">
                 All Campaigns Stats
                <Col md={6}>
                    <div className="summary-cards d-flex flex-wrap">
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #7fc87f, #008B00'}}>
                            <h5>{allSentCount}</h5>
                                <p>Sent</p>
                            </Card.Body>
                        </Card>
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #a9d8d8, cadetblue'}}>
                                <h5>{allScheduledCount}</h5>
                                <p>Scheduled</p>
                            </Card.Body>
                        </Card>
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #a9d8d8, cadetblue'}}>
                                <h5>{allDraftCount}</h5>
                                <p>Drafts</p>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>

                 Recent Campaign Stats
                <Col md={6}>
                    <div className="summary-cards d-flex flex-wrap">
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #7fc87f, #008B00'}}>
                                <h5>{recentSendCount}</h5>
                                <p>Sent</p>
                            </Card.Body>
                        </Card>
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #a9d8d8, cadetblue'}}>
                            <h5>{recentScheduledCount}</h5>
                                <p>Scheduled</p>
                            </Card.Body>
                        </Card>
                        <Card className="summary-card text-center mb-3">
                            <Card.Body style={{background:'linear-gradient(to bottom right, #a9d8d8, cadetblue'}}>
                                <h5>{recentDraftCount}</h5>
                                <p>Drafts</p>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>*/}

            {/* Manage Campaign Status */}
        </div>
    );
};

export default CampaignPage;
