import React, { useState, useEffect } from 'react';
import {Form, Button, ListGroup, Col, Card, ProgressBar} from 'react-bootstrap';
import {FaPlus, FaTag, FaUsers} from 'react-icons/fa';
import axios from 'axios';
import AnimatedWorkFlowIcon from "../icons/WorkflowIcon";

const CreateWorkflowForm = ({ onCreateWorkflow }) => {
    const [workflowData, setWorkflowData] = useState({
        name: '',
        industryTag: '',
        activity: '',
    });


    const [localUserId, setLocalUserId] = useState({
        user_id: '',
    });
    const [templateContent, setTemplateContent] = useState(''); // State for live template preview

    // Dummy subscriber data
    const dummySubscribers = [
        { id: 1, name: 'John Doe', email: 'john.doe@example.com', industry: 'Finance', status: 'confirmed', openedEmails: true },
        { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', industry: 'Marketing', status: 'unconfirmed', openedEmails: false },
        { id: 3, name: 'Robert Brown', email: 'robert.brown@example.com', industry: 'Healthcare', status: 'confirmed', openedEmails: true },
        // Add more dummy subscribers as needed
    ];

    const resetFields = () => {
        setWorkflowData({
            name: '',
            industryTag: '',
            activity: '',
        });
        setTemplateContent(''); // Clear preview when category changes
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'name') {
            resetFields();
            setWorkflowData({ name: value, industryTag: '', activity: '' });
        } else {
            setWorkflowData(prevData => ({ ...prevData, [name]: value }));
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setLocalUserId(prevData => ({ ...prevData, user_id: user.id }));
        }
    }, []);

    // Fetch template content when category (workflow name) changes
    useEffect(() => {
        const fetchTemplateContent = async () => {
            if (!workflowData.name) {
                setTemplateContent(''); // Clear if no category selected
                return;
            }

            try {
                // Replace user_id with the actual user ID
                const user_id = localUserId.user_id; // Example user ID, adjust accordingly
                const response = await axios.get(`/server/crm_function/api/templates`, {
                    params: {
                        category: workflowData.name,
                        user_id: user_id
                    }
                });

                if (response.data && response.data.template) {
                    setTemplateContent(response.data.template.content || '');
                } else {
                    setTemplateContent(''); // Clear if no template found
                }
            } catch (error) {
                console.error('Error fetching template content:', error);
                setTemplateContent(''); // Clear on error
            }
        };

        fetchTemplateContent();
    }, [workflowData.name]); // Trigger when category (workflow name) changes

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreateWorkflow(workflowData);
    };


    const filteredSubscribers = dummySubscribers.filter(subscriber => {
        const matchesWorkflow = (() => {
            switch (workflowData.name) {
                case 'Thank you for your business':
                    return subscriber.status === 'confirmed';
                case 'Opened Email List':
                case 'Opened Email Hot List':
                    return subscriber.openedEmails;
                case 'Top of Mind':
                    return true;
                case 'Advertisement':
                case 'Sale':
                    return !workflowData.industryTag || subscriber.industry === workflowData.industryTag;
                default:
                    return false;
            }
        })();

        return matchesWorkflow;
    });

    return (

        <Col>

            <Card className="recent-campaign-card mb-3" style={{ height: '100%' , backgroundColor:'white', marginBottom:'0px'}}>
                <h1 style={{textAlign:'center'}}>Campaigns and Workflows</h1>
                <AnimatedWorkFlowIcon/>

                <div className="workflow-create-container p-4" style={{ position: 'relative', background:'white', borderRadius: '8px' }}>
                    <div className="d-flex align-items-center mb-4">
                        <h3 style={{ color: '#FF7043',margin:'auto',textAlign:'center'}}>Choose an Automated Campaign or Instant Campaign</h3>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        {/* Workflow Name Dropdown */}
                        <Form.Group className="mb-3">
                            <div className="d-flex align-items-center mb-2">
                                <FaTag size={20} style={{ color: '#66BB6A', marginRight: '10px' }} />
                                <Form.Label style={{ color: '#66BB6A' }}>Workflow Name</Form.Label>
                            </div>
                            <Form.Control
                                as="select"
                                name="name"
                                value={workflowData.name}
                                onChange={handleInputChange}
                                style={{ borderColor: '#66BB6A',color:'white',backgroundColor:'darkslateblue' }}
                            >
                                <option value="">Select Workflow Name</option>
                                <option value="Top of Mind">Top of Mind</option>
                                <option value="Advertisement">Advertisement</option>
                                <option value="Sale">Sale</option>
                                <option value="Opened Email List">Opened Email List</option>
                                <option value="Opened Email Hot List">Repeated Opened Email Hot List</option>
                                <option value="Thank you for your business">Thank you for your business</option>
                            </Form.Control>
                        </Form.Group>

                        {/* Conditionally Render Industry Tag Dropdown */}
                        {(workflowData.name === 'Advertisement' || workflowData.name === 'Sale') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center mb-2">
                                    <FaTag size={20} style={{ color: '#42A5F5', marginRight: '10px' }} />
                                    <Form.Label style={{ color: '#42A5F5' }}>Industry Tag (Optional)</Form.Label>
                                </div>
                                <Form.Control
                                    as="select"
                                    name="industryTag"
                                    value={workflowData.industryTag}
                                    onChange={handleInputChange}
                                    style={{ borderColor: '#42A5F5' }}
                                >
                                    <option value="">Select Industry</option>
                                    <option value="Finance">Finance</option>
                                    <option value="Marketing">Marketing</option>
                                    <option value="Healthcare">Healthcare</option>
                                </Form.Control>
                            </Form.Group>
                        )}

                        {/* Conditionally Render Workflow Contact Frequency */}
                        {(workflowData.name === 'Opened Email List') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center mb-2">
                                    <FaUsers size={20} style={{ color: '#FF7043', marginRight: '10px' }} />
                                    <Form.Label style={{ color: '#FF7043' }}>Workflow Contact Frequency</Form.Label>
                                </div>
                                <Form.Control
                                    as="select"
                                    name="activity"
                                    value={workflowData.activity}
                                    onChange={handleInputChange}
                                    style={{ borderColor: '#FF7043' }}
                                >
                                    <option value="">Select contact frequency</option>
                                    <option value="2 weeks">Once every two weeks</option>
                                    <option value="3 weeks">Once every three weeks</option>
                                    <option value="4 weeks">Once every four weeks</option>
                                </Form.Control>
                            </Form.Group>
                        )}

                        {(workflowData.name === 'Opened Email Hot List') && (
                            <Form.Group className="mb-3">
                                <div className="d-flex align-items-center mb-2">
                                    <FaUsers size={20} style={{ color: '#FF7043', marginRight: '10px' }} />
                                    <Form.Label style={{ color: '#FF7043' }}>Workflow Contact Frequency</Form.Label>
                                </div>
                                <Form.Control
                                    as="select"
                                    name="activity"
                                    value={workflowData.activity}
                                    onChange={handleInputChange}
                                    style={{ borderColor: '#FF7043' }}
                                >
                                    <option value="">Select contact frequency</option>
                                    <option value="1 week">Once every week</option>
                                    <option value="2 weeks">Once every two weeks</option>
                                    <option value="3 weeks">Once every three weeks</option>
                                    <option value="4 weeks">Once every four weeks</option>
                                </Form.Control>
                            </Form.Group>
                        )}

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                type="submit"
                                variant="primary"
                                style={{ background: 'linear-gradient(to right, #FF7043, #FFAB91)', border: 'none' }}
                            >
                                Continue to Template Design
                            </Button>
                        </div>

                    </Form>

                    {/* Live Template Preview Section */}
                    {workflowData.name && (
                        <h4 className="mt-4" style={{ color: '#FF7043',textAlign:"center" }}>
                            Your current {workflowData.name}
                        </h4>
                    )}

                    <div className="template-preview" style={{ backgroundColor: 'white', padding: '10px', borderRadius: '8px', marginTop: '10px' }}>
                        {templateContent ? (
                            <div dangerouslySetInnerHTML={{ __html: templateContent }} />
                        ) : (
                            <p style={{ color: 'gray' }}>No preview available for the selected workflow.</p>
                        )}
                    </div>

                    {/* Filtered Subscribers Section */}

                    <p style={{ color: 'white' }}>Showing subscribers filtered by workflow:</p>
                    <ListGroup>
                        {filteredSubscribers.length > 0 ? (
                            filteredSubscribers.map((subscriber) => (
                                <ListGroup.Item key={subscriber.id}>
                                    <strong>{subscriber.name}</strong> - {subscriber.email} (Industry: {subscriber.industry})
                                </ListGroup.Item>
                            ))
                        ) : (
                            <ListGroup.Item>No subscribers match the current selections.</ListGroup.Item>
                        )}
                    </ListGroup>
                </div>
            </Card>
        </Col>


    );
};

export default CreateWorkflowForm;
