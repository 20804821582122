import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Form, Toast, ToastContainer } from 'react-bootstrap';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [showModal, setShowModal] = useState(false); // Modal visibility
    const [showToast, setShowToast] = useState(false); // Toast visibility
    const [toastMessage, setToastMessage] = useState(''); // Toast message
    const navigate = useNavigate();

    // Handle sign-up
    const handleSignUp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/server/crm_function/api/auth/signup/', { email, password, name });
            const { message } = response.data; // Extract success message

            // Show toast on success
            setToastMessage('Sign-up successful! Please check your email to verify your account.');
            setShowToast(true);

            // Show modal prompting user to check email
            setShowModal(true);
        } catch (error) {
            console.error('Sign-up failed', error);
            setToastMessage('Sign-up failed. Please try again.');
            setShowToast(true);
        }
    };

    // Handle modal close and redirect to sign-in
    const handleModalClose = () => {
        setShowModal(false);
        navigate('/app/signin'); // Redirect to sign-in page
    };

    return (
        <div>
            {/* Toast Notification */}
            <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1050 }}>
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide bg="success" text="white">
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* Sign-Up Form */}
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ background: 'linear-gradient(to bottom right, #f0f4f8, #c8e6c9)' }}>
                <Form onSubmit={handleSignUp} className="p-4 rounded shadow bg-white" style={{ maxWidth: '400px', width: '100%' }}>
                    <h3 className="text-center mb-4">Sign Up</h3>
                    {/* Name Field */}
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter your name"
                            required
                        />
                    </Form.Group>

                    {/* Email Field */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter email"
                            required
                        />
                    </Form.Group>

                    {/* Password Field */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter password"
                            required
                        />
                    </Form.Group>

                    {/* Sign-Up Button */}
                    <Button style={{ backgroundColor: 'steelblue' }} variant="primary" type="submit" className="w-100">
                        Sign Up
                    </Button>
                </Form>
            </div>

            {/* Modal Prompting Email Verification */}
            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Email Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sign-up successful! Please check your email inbox for a verification link to complete the process.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleModalClose}>
                        Go to Sign In
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SignUp;
